import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ['editTableNameButton']

  static values = {
    tenantId: String
  }
  connect() {
  }

  toggleEditTableName(e) {
    let name_label = e.target.closest('.card-title').querySelector('.table-name-label')
    let name_form = e.target.closest('.card-title').querySelector('.table-name-form')
    if (name_label.classList.contains('d-none')) {
      name_label.classList.remove('d-none')
      name_form.classList.add('d-none')
      name_form.classList.remove('d-flex')
    } else {
      name_label.classList.add('d-none')
      name_form.classList.add('d-flex')
      name_form.classList.remove('d-none')
    }
  }

}
